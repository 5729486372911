import ce, { CSSStyle } from './create-element';
import { SPACING_HALF, REDUCED_LINE_HEIGHT } from './constants';
import { LayoutType } from './types';

interface FormGroupProps {
  labelText: string;
  labelStyle: CSSStyle;
  input: HTMLElement;
  layout: LayoutType;
}

interface CreateFormGroupProps {
  labelText: string;
  labelStyle: CSSStyle;
  input: HTMLElement;
}

const createHorizontalFormGroup = ({
  labelStyle,
  labelText,
  input,
}: CreateFormGroupProps): HTMLDivElement =>
  ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        margin: `${SPACING_HALF}px 0`,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0px',
        marginRight: '10px',
        justifyContent: 'space-between',
      },
      className: 'horizontal-widget-form-group',
    },
    [
      ce(
        'label',
        {
          style: {
            ...labelStyle,
            lineHeight: REDUCED_LINE_HEIGHT,
            boxSizing: 'border-box',
            display: 'inline-block',
            margin: '0 0 5px',
            padding: `0 ${SPACING_HALF}px 0 0`,
            verticalAlign: 'middle',
          },
        },
        [labelText]
      ),
      input,
    ]
  );

const createVerticalFormGroup = ({
  labelStyle,
  labelText,
  input,
}: CreateFormGroupProps): HTMLDivElement =>
  ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        margin: `${SPACING_HALF}px 0`,
      },
    },
    [
      ce(
        'label',
        {
          style: {
            ...labelStyle,
            lineHeight: REDUCED_LINE_HEIGHT,
            boxSizing: 'border-box',
            display: 'inline-block',
            width: '50%',
            maxWidth: '50%',
            margin: '0',
            padding: `0 ${SPACING_HALF}px 0 0`,
            verticalAlign: 'middle',
          },
        },
        [labelText]
      ),
      input,
    ]
  );

const createFormGroup = ({
  labelStyle,
  labelText,
  input,
  layout,
}: FormGroupProps): HTMLDivElement => {
  if (layout === 'horizontal') {
    return createHorizontalFormGroup({
      labelStyle,
      labelText,
      input,
    });
  }
  return createVerticalFormGroup({
    labelStyle,
    labelText,
    input,
  });
};

export default createFormGroup;
