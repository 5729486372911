import ce, { CSSStyle } from './create-element';
import {
  SPACING,
  FONT_SIZE_TITLE,
  SPACING_HALF,
  LOGO_WIDTH_MODAL,
  MODAL_CLOSE_HTML,
  WHITE,
  RIGHT_CARET,
  SMALL_LINE_HEIGHT,
} from './constants';
import createLogoHTML from './create-logo-html';
import { CalculatorStyleOptions, CalculatorType } from './types';
import createButton from './create-button';

const MAP_MODAL_HEADERS: Record<CalculatorType, string> = {
  listing: 'Pay less to secure this property',
  page: 'Pay less to secure your perfect property',
  'budget-booster-listing': 'Pay less to secure your perfect property',
  'budget-booster-listing-cg': 'Pay less to secure your perfect property',
  'budget-booster-listing-arun': 'Pay less to secure your perfect property',
  'hw-calculator-listing-retirement':
    'Pay less to secure your ideal retirement home',
  'budget-booster-page': 'Secure your ideal home with a boosted budget',
  'budget-booster-page-cg': 'Secure your ideal home with a boosted budget',
  'budget-booster-page-arun': 'Secure your ideal home with a boosted budget',
  'hw-calculator-page-retirement':
    'Secure your ideal home with a boosted budget',
};

const MAP_MODAL_BUTTON: Partial<Record<CalculatorType, string>> = {
  listing: 'See estimate now',
  page: 'See estimate now',
  'budget-booster-listing': 'See estimate now',
  'budget-booster-listing-cg': 'See estimate now',
  'budget-booster-listing-arun': 'See estimate now',
  'budget-booster-page': 'See estimate now',
  'budget-booster-page-cg': 'See estimate now',
  'budget-booster-page-arun': 'See estimate now',
};

interface ModalProps {
  callback: () => void;
  styleOptions: CalculatorStyleOptions;
  type: CalculatorType;
  companyName?: string;
}

const createModal = ({
  callback,
  type,
  styleOptions: {
    modalZIndex,
    lineHeight,
    modalHeaderBackgroundColor,
    modalHeaderTitleFontFamily,
    modalHeaderTitleFontSize,
    modalHeaderTitleFontWeight,
    modalHeaderTitleTextColor,
    modalHeaderTitleTextTransform,
    modalBodyBackgroundColor,
    modalBodyFontFamily,
    modalBodyFontSize,
    modalBodyFontWeight,
    modalBodyTextColor,
    modalButtonColor,
    modalButtonHoverColor,
    modalButtonFontFamily,
    modalButtonFontWeight,
    modalButtonFontSize,
    modalButtonTextColor,
    modalButtonHoverTextColor,
    modalButtonTextTransform,
    modalLogoColored,
  },
  companyName = 'the referring company',
}: ModalProps): {
  modalOverlay: HTMLDivElement;
  modalBody: HTMLDivElement;
  modalBodyBottom: HTMLDivElement;
} => {
  const modalBody = ce('div', {
    style: {
      boxSizing: 'border-box',
      padding: `${SPACING}px`,
    },
  });

  const modalBodyBottom = ce('div', {
    style: {
      boxSizing: 'border-box',
      paddingLeft: `${SPACING}px`,
      paddingRight: `${SPACING}px`,
    },
  });

  const bodyTextStyles: CSSStyle = {
    fontFamily: modalBodyFontFamily,
    fontSize: modalBodyFontSize,
    fontWeight: modalBodyFontWeight,
    color: modalBodyTextColor,
  };

  const noShadowBoxOnArunModal: CSSStyle =
    type === 'budget-booster-listing-arun' ||
    type === 'budget-booster-page-arun'
      ? { boxShadow: 'none' }
      : {};
  const addHoverBorderOnArunModal: CSSStyle =
    type === 'budget-booster-listing-arun' ||
    type === 'budget-booster-page-arun'
      ? { border: `1px solid ${modalButtonColor}` }
      : {};

  const modalOverlay = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        padding: `0 ${SPACING}px`,
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'none',
        zIndex: modalZIndex,
        lineHeight: lineHeight,
        overflow: 'auto',
      },
    },
    [
      ce(
        'div',
        {
          style: {
            boxSizing: 'border-box',
            margin: `${SPACING}px auto`,
            width: '100%',
            maxWidth: '856px',
            backgroundColor: modalBodyBackgroundColor,
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.3)',
          },
        },
        [
          ce(
            'div',
            {
              style: {
                boxSizing: 'border-box',
                position: 'relative',
                padding: `${SPACING}px ${SPACING *
                  2}px ${SPACING}px ${SPACING}px`,
                backgroundColor: modalHeaderBackgroundColor,
              },
            },
            [
              ce(
                'h2',
                {
                  style: {
                    padding: '0',
                    margin: '0',
                    lineHeight: SMALL_LINE_HEIGHT,
                    color: modalHeaderTitleTextColor,
                    fontFamily: modalHeaderTitleFontFamily,
                    fontSize: modalHeaderTitleFontSize,
                    fontWeight: modalHeaderTitleFontWeight,
                    textTransform: modalHeaderTitleTextTransform,
                  },
                },
                [MAP_MODAL_HEADERS[type]]
              ),
              ce('div', {
                style: {
                  boxSizing: 'border-box',
                  padding: `${SPACING_HALF}px`,
                  margin: `${SPACING_HALF}px`,
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  cursor: 'pointer',
                  fontSize: `${FONT_SIZE_TITLE}px`,
                  fontWeight: 'bold',
                  fill: modalHeaderTitleTextColor,
                },
                innerHTML: MODAL_CLOSE_HTML,
                onClick: () => {
                  modalOverlay.style.display = 'none';
                },
              }),
            ]
          ),
          modalBody,
          ce(
            'div',
            {
              style: {
                boxSizing: 'border-box',
                padding: `0 ${SPACING}px`,
                textAlign: 'center',
                display:
                  type === 'hw-calculator-listing-retirement' ||
                  type === 'hw-calculator-page-retirement'
                    ? 'none'
                    : 'block',
              },
            },
            [
              createButton({
                customStyles: {
                  display: 'inline-flex',
                  backgroundColor: modalButtonColor,
                  color: modalButtonTextColor,
                  fontSize: modalButtonFontSize,
                  textTransform: modalButtonTextTransform,
                  fontFamily: modalButtonFontFamily,
                  fontWeight: modalButtonFontWeight,
                  ...noShadowBoxOnArunModal,
                  ...addHoverBorderOnArunModal,
                },
                hoverStyles: {
                  backgroundColor: modalButtonHoverColor,
                  color: modalButtonHoverTextColor,
                  ...noShadowBoxOnArunModal,
                  ...addHoverBorderOnArunModal,
                },
                onClick: callback,
                text: MAP_MODAL_BUTTON[type] || 'listing',
                icon: RIGHT_CARET,
                layout: 'vertical',
              }),
            ]
          ),
          modalBodyBottom,
          ce(
            'div',
            {
              style: {
                boxSizing: 'border-box',
                position: 'relative',
                padding: `${SPACING_HALF}px ${SPACING}px`,
                marginTop: `${SPACING}px`,
                borderTop: `1px solid ${modalHeaderBackgroundColor}`,
              },
              className: 'modal-footer',
            },
            [
              ce('div', {
                style: {
                  float: 'right',
                  width: `${LOGO_WIDTH_MODAL}px`,
                  marginLeft: `${SPACING}px`,
                  fill: WHITE,
                },
                innerHTML: createLogoHTML({
                  width: LOGO_WIDTH_MODAL,
                  colored: modalLogoColored,
                }),
              }),
              ce('div', {}, [
                ce(
                  'p',
                  {
                    style: {
                      ...bodyTextStyles,
                      padding: '0',
                      margin: '0',
                      marginBottom: `${SPACING_HALF}px`,
                      fontSize: '14px',
                      lineHeight: '1em',
                      textAlign: 'left',
                    },
                  },
                  [
                    type === 'hw-calculator-listing-retirement' ||
                    type === 'hw-calculator-page-retirement'
                      ? `Please note: These estimated figures are based on a retirement property and are only intended as a guide; accurate figures can be obtained by calling Homewise directly. A Home for Life Plan is exclusively for ages 60 and older and can be used for any property of your choice that you wish to move to (subject to Terms and Conditions and property criteria). If you become a Homewise customer, ${companyName} may receive an introduction fee from Homewise`
                      : `Please note: These estimated figures are based on a freehold property and are only intended as a guide; accurate figures should be obtained from Homewise. 
                    A Home for Life Plan can be purchased for a property of your choice that you wish to move to, (subject to Terms and Conditions and property criteria). If you become a Homewise customer, ${companyName} may receive a payment from Homewise for the introduction`,
                    type === 'budget-booster-listing-cg' ||
                    type === 'budget-booster-page-cg'
                      ? '; this may be up to 1% of the property purchase price under the Home for Life Plan.'
                      : '.',
                  ]
                ),
                ce(
                  'p',
                  {
                    style: {
                      ...bodyTextStyles,
                      padding: '0',
                      margin: '0',
                      fontSize: '14px',
                      lineHeight: '1em',
                    },
                  },
                  [
                    type === 'page' || type === 'listing'
                      ? '*Subject to terms and conditions, property criteria applies. Visit www.homewise.co.uk/calculator for more information.'
                      : '',
                  ]
                ),
              ]),
              ce('div', {
                style: {
                  clear: 'both',
                },
              }),
            ]
          ),
        ]
      ),
    ]
  );

  return {
    modalOverlay,
    modalBody,
    modalBodyBottom,
  };
};

export default createModal;
