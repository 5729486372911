import ce, { ChangeEvent, CSSStyle } from './create-element';
import {
  Values,
  CalculatorStyleOptions,
  CalculatorType,
  LayoutType,
} from './types';
import {
  SPACING,
  SPACING_HALF,
  GENDER_OPTIONS,
  AGE_OPTIONS,
  PERSONS_OPTIONS,
  POWERED_BY_WIDTH,
  HORIZONTAL_POWERED_BY_WIDTH,
  LOGO_WIDTH,
  HORIZONTAL_LOGO_WIDTH,
  SPACING_QUARTER,
  LOGO_ASPECT,
  COMMON_INPUT_STYLES,
  BUTTON_ICON_SIZE,
  WHITE,
  MAX_FORM_WIDTH,
  CALIBRI,
  GREY_DARK,
  RIGHT_CARET,
} from './constants';
import createFormGroup from './create-form-group';
import createSelect from './create-select';
import createLogoHTML from './create-logo-html';
import createBoosterIconLogoHTML from './create-booster-icon-logo-html';
import { serializePrice } from './serialize';
import createButton from './create-button';

const MAP_OF_HEADERS: Record<CalculatorType, string> = {
  page: 'Aged 60 or over?',
  listing: 'Aged 60 or over?',
  'budget-booster-listing': 'Aged 60 or over?',
  'budget-booster-listing-cg': 'Aged 60 or over?',
  'budget-booster-listing-arun': 'Aged 60 or over?',
  'budget-booster-page': 'Aged 60 or over?',
  'budget-booster-page-cg': 'Aged 60 or over?',
  'budget-booster-page-arun': 'Aged 60 or over?',
  'hw-calculator-listing-retirement': 'Unique Saving Solution',
  'hw-calculator-page-retirement': 'Unique Saving Solution',
};

const MAP_OF_BODY_TITLES: Record<CalculatorType, string> = {
  page: 'You could pay less to secure a property',
  listing: 'You could pay less to secure this property',
  'budget-booster-listing':
    'You could secure this property for less, with a Home for Life Plan',
  'budget-booster-listing-cg':
    'You could secure this, or any of our other properties, for less with a Lifetime Lease',
  'budget-booster-listing-arun':
    'You could secure this property for thousands less with a Home for Life Plan.',
  'hw-calculator-listing-retirement':
    'You could secure this retirement home for thousands less with a Home for Life Plan.',
  'budget-booster-page':
    'You could secure your dream home, with a Home for Life Plan',
  'budget-booster-page-cg':
    'You could secure your dream home, with a Lifetime Lease',
  'budget-booster-page-arun':
    'You could secure your dream home for thousands less with a Home for Life Plan.',
  'hw-calculator-page-retirement':
    'You could secure your new home for thousands less with a Home for Life Plan',
};

const MAP_OF_SUBTITLES: Record<CalculatorType, string> = {
  page: 'Calculate your saving below',
  listing: 'Calculate your saving below',
  'budget-booster-listing': 'Calculate your saving below',
  'budget-booster-listing-cg': 'Calculate your saving and find out more',
  'budget-booster-listing-arun': '',
  'hw-calculator-listing-retirement': '',
  'budget-booster-page': 'Calculate your new budget below',
  'budget-booster-page-cg': 'Calculate your new budget below',
  'budget-booster-page-arun': '',
  'hw-calculator-page-retirement': '',
};

interface WidgetProps {
  onSubmit: (event: Event) => void;
  values: Values;
  errors: HTMLDivElement;
  styleOptions: CalculatorStyleOptions;
  type: CalculatorType;
  includeGender: boolean;
  layout: LayoutType;
}

const createHorizontalWidget = ({
  styleOptions: {
    lineHeight,
    lineBreakerColor,
    widgetBorderColor,
    widgetBackgroundColor,
    widgetTitleFontFamily,
    widgetTitleFontSize,
    widgetTitleFontWeight,
    widgetTitleTextColor,
    widgetTitleTextTransform,
    widgetLogoColored,
    formTitleFontFamily,
    formTitleFontSize,
    formTitleFontWeight,
    formTitleTextColor,
    formTitleTextTransform,
    formLabelFontFamily,
    formLabelFontSize,
    formLabelFontWeight,
    formLabelTextColor,
    formButtonColor,
    formButtonHoverColor,
    formButtonFontFamily,
    formButtonFontWeight,
    formButtonFontSize,
    formButtonTextColor,
    formButtonHoverTextColor,
    formButtonTextTransform,
    boosterIconLogoWidth,
    boosterIconLogoColor,
  },
  includeGender,
  onSubmit,
  values,
  errors,
  type,
  layout,
}: WidgetProps): HTMLDivElement => {
  const labelStyle: CSSStyle = {
    fontFamily: formLabelFontFamily,
    fontSize: formLabelFontSize,
    fontWeight: formLabelFontWeight,
    color: formLabelTextColor,
  };

  const header = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        marginRight: '10px',
      },
    },
    [
      ce(
        'div',
        {
          style: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          },
        },
        [
          ...(type === 'hw-calculator-listing-retirement' ||
          type === 'hw-calculator-page-retirement'
            ? []
            : [
                ce('div', {
                  style: {
                    marginRight: '15px',
                  },
                  innerHTML: createBoosterIconLogoHTML({
                    width: boosterIconLogoWidth,
                    color: boosterIconLogoColor,
                  }),
                }),
              ]),
          ce(
            'h3',
            {
              style: {
                boxSizing: 'border-box',
                textAlign: 'left',
                padding: '0',
                margin: `0`,
                lineHeight: 'normal',
                fontSize: widgetTitleFontSize,
                color: widgetTitleTextColor,
                fontWeight: widgetTitleFontWeight,
                fontFamily: widgetTitleFontFamily,
                textTransform: widgetTitleTextTransform,
                marginBottom: '15px',
                marginTop: '10px',
              },
              className: 'horizontal-widget-header',
            },
            [MAP_OF_HEADERS[type]]
          ),
        ]
      ),
      ce('hr', {
        style: {
          margin: '15px auto',
          backgroundColor: lineBreakerColor,
        },
        color: lineBreakerColor,
      }),
      ce(
        'p',
        {
          style: {
            boxSizing: 'border-box',
            padding: `0`,
            margin: '0',
            marginBottom: `${SPACING_QUARTER}px`,
            textAlign: 'left',
            fontFamily: formTitleFontFamily,
            fontSize: formTitleFontSize,
            fontWeight: formTitleFontWeight,
            color: formTitleTextColor,
            textTransform: formTitleTextTransform,
          },
          className: 'horizontal-widget-sub-header',
        },
        [MAP_OF_BODY_TITLES[type]]
      ),
    ]
  );

  const secondPerson = ce(
    'div',
    {
      style: {
        display: values.persons === '2' ? 'inline-block' : 'none',
        flex: '1',
      },
    },
    [
      ...(includeGender
        ? [
            createFormGroup({
              labelText: 'Gender 2',
              labelStyle,
              input: createSelect({
                options: GENDER_OPTIONS,
                initialValue: values.gender2 || '',
                onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                  values.gender2 = event.currentTarget.value;
                },
                layout,
              }),
              layout,
            }),
          ]
        : []),
      createFormGroup({
        labelText: 'Age 2 (Years)',
        labelStyle,
        input: createSelect({
          options: AGE_OPTIONS,
          initialValue: values.age2,
          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
            values.age2 = event.currentTarget.value;
          },
          layout,
        }),
        layout,
      }),
    ]
  );

  const formGroup = ce(
    'div',
    {
      style: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px',
      },
      className: 'horizontal-widget-form',
    },
    [
      createFormGroup({
        labelText:
          type === 'budget-booster-page-arun' ||
          type === 'hw-calculator-page-retirement'
            ? 'Current budget'
            : 'Property price',
        labelStyle,
        input: ce('input', {
          style: {
            ...COMMON_INPUT_STYLES,
            width: '100%',
            maxWidth: '100%',
          },
          value: values.price,
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            const price = serializePrice(event.currentTarget.value);

            event.currentTarget.value = price;
            values.price = price;
          },
        }),
        layout,
      }),
      createFormGroup({
        labelText: 'Who’s moving?',
        labelStyle,
        input: createSelect({
          options: PERSONS_OPTIONS,
          initialValue: values.persons,
          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
            values.persons = event.currentTarget.value;
            secondPerson.style.display =
              values.persons === '2' ? 'block' : 'none';
          },
          layout,
        }),
        layout,
      }),
      ...(includeGender
        ? [
            createFormGroup({
              labelText: 'Gender',
              labelStyle,
              input: createSelect({
                options: GENDER_OPTIONS,
                initialValue: values.gender1 || '',
                onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                  values.gender1 = event.currentTarget.value;
                },
                layout,
              }),
              layout,
            }),
          ]
        : []),
      createFormGroup({
        labelText: 'Age (Years)',
        labelStyle,
        input: createSelect({
          options: AGE_OPTIONS,
          initialValue: values.age1,
          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
            values.age1 = event.currentTarget.value;
          },
          layout,
        }),
        layout,
      }),
      secondPerson,
    ]
  );

  const footer = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
      },
      className: 'horizontal-widget-logo',
    },
    [
      ce(
        'p',
        {
          style: {
            boxSizing: 'border-box',
            display: 'inline-block',
            width: `${HORIZONTAL_POWERED_BY_WIDTH}px`,
            fontSize: '13px',
            textAlign: 'right',
            verticalAlign: 'top',
            marginTop: '13px',
            padding: `${SPACING_QUARTER}px ${SPACING_HALF}px 0 0`,
            lineHeight: '1em',
            fontFamily: CALIBRI,
            color: widgetLogoColored ? GREY_DARK : WHITE,
          },
        },
        ['Powered by']
      ),
      ce('div', {
        style: {
          boxSizing: 'border-box',
          display: 'inline-block',
          width: `${HORIZONTAL_LOGO_WIDTH}px`,
          height: `${HORIZONTAL_LOGO_WIDTH * LOGO_ASPECT}px`,
        },
        innerHTML: createLogoHTML({
          width: HORIZONTAL_LOGO_WIDTH,
          colored: widgetLogoColored,
        }),
      }),
    ]
  );

  const widget = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        border: `1px solid ${widgetBorderColor}`,
        borderRadius: '10px',
        lineHeight: lineHeight,
        clear: 'both',
        padding: `${SPACING_HALF}px`,
        backgroundColor: `${widgetBackgroundColor}`,
      },
    },
    [
      header,
      ce(
        'form',
        {
          style: {
            boxSizing: 'border-box',
            margin: '0 auto',
          },
          onSubmit,
        },
        [
          formGroup,
          errors,
          ce(
            'div',
            {
              style: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: '10px',
              },
              className: 'horizontal-widget-footer',
            },
            [
              createButton({
                customStyles: {
                  backgroundColor: formButtonColor,
                  color: formButtonTextColor,
                  fontSize: formButtonFontSize,
                  textTransform: formButtonTextTransform,
                  fontFamily: formButtonFontFamily,
                  fontWeight: formButtonFontWeight,
                  padding: `${SPACING_QUARTER}px ${BUTTON_ICON_SIZE}px`,
                  boxShadow: 'none',
                  border: `1px solid ${formButtonColor}`,
                },
                hoverStyles: {
                  backgroundColor: formButtonHoverColor,
                  color: formButtonHoverTextColor,
                  boxShadow: 'none',
                  border: `1px solid ${formButtonColor}`,
                },
                text: 'Calculate',
                layout,
              }),
              footer,
            ]
          ),
        ]
      ),
    ]
  );

  return widget;
};

const createVerticalWidget = ({
  styleOptions: {
    lineHeight,
    widgetBackgroundColor,
    widgetTitleFontFamily,
    widgetTitleFontSize,
    widgetTitleFontWeight,
    widgetTitleTextColor,
    widgetTitleTextTransform,
    widgetLogoColored,
    formBackgroundColor,
    formTitleFontFamily,
    formTitleFontSize,
    formTitleFontWeight,
    formTitleTextColor,
    formTitleTextTransform,
    formSubtitleFontFamily,
    formSubtitleFontSize,
    formSubtitleFontWeight,
    formSubtitleTextColor,
    formSubtitleTextTransform,
    formLabelFontFamily,
    formLabelFontSize,
    formLabelFontWeight,
    formLabelTextColor,
    formButtonColor,
    formButtonHoverColor,
    formButtonFontFamily,
    formButtonFontWeight,
    formButtonFontSize,
    formButtonTextColor,
    formButtonHoverTextColor,
    formButtonTextTransform,
  },
  includeGender,
  onSubmit,
  values,
  errors,
  type,
  layout,
}: WidgetProps): HTMLDivElement => {
  const labelStyle: CSSStyle = {
    fontFamily: formLabelFontFamily,
    fontSize: formLabelFontSize,
    fontWeight: formLabelFontWeight,
    color: formLabelTextColor,
  };

  const header = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        padding: `${SPACING}px`,
        backgroundColor: widgetBackgroundColor,
      },
    },
    [
      ce(
        'h3',
        {
          style: {
            boxSizing: 'border-box',
            textAlign: 'center',
            padding: '0',
            margin: `0`,
            lineHeight: 'normal',
            fontSize: widgetTitleFontSize,
            color: widgetTitleTextColor,
            fontWeight: widgetTitleFontWeight,
            fontFamily: widgetTitleFontFamily,
            textTransform: widgetTitleTextTransform,
          },
        },
        [MAP_OF_HEADERS[type]]
      ),
    ]
  );

  const secondPerson = ce(
    'div',
    {
      style: {
        marginTop: `${SPACING_HALF}px`,
        display: values.persons === '2' ? 'block' : 'none',
      },
    },
    [
      ...(includeGender
        ? [
            createFormGroup({
              labelText: 'Gender 2',
              labelStyle,
              input: createSelect({
                options: GENDER_OPTIONS,
                initialValue: values.gender2 || '',
                onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                  values.gender2 = event.currentTarget.value;
                },
                layout,
              }),
              layout,
            }),
          ]
        : []),
      createFormGroup({
        labelText: 'Age 2 (Years)',
        labelStyle,
        input: createSelect({
          options: AGE_OPTIONS,
          initialValue: values.age2,
          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
            values.age2 = event.currentTarget.value;
          },
          layout,
        }),
        layout,
      }),
    ]
  );

  const footer = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        padding: `${SPACING_HALF}px ${SPACING}px`,
        backgroundColor: widgetBackgroundColor,
      },
    },
    [
      ce(
        'div',
        {
          style: {
            boxSizing: 'border-box',
            margin: '0 auto',
            width: `${POWERED_BY_WIDTH * 2 + LOGO_WIDTH}px`,
          },
        },
        [
          ce(
            'p',
            {
              style: {
                boxSizing: 'border-box',
                display: 'inline-block',
                width: `${POWERED_BY_WIDTH}px`,
                fontSize: '16px',
                textAlign: 'right',
                verticalAlign: 'top',
                margin: '0',
                padding: `${SPACING_QUARTER}px ${SPACING_HALF}px 0 0`,
                lineHeight: '1em',
                fontFamily: CALIBRI,
                color: widgetLogoColored ? GREY_DARK : WHITE,
              },
            },
            ['Powered by']
          ),
          ce('div', {
            style: {
              boxSizing: 'border-box',
              display: 'inline-block',
              width: `${LOGO_WIDTH}px`,
              height: `${LOGO_WIDTH * LOGO_ASPECT}px`,
              fill: WHITE,
            },
            innerHTML: createLogoHTML({
              width: LOGO_WIDTH,
              colored: widgetLogoColored,
            }),
          }),
        ]
      ),
    ]
  );

  const widget = ce(
    'div',
    {
      style: {
        boxSizing: 'border-box',
        border: `2px solid ${widgetBackgroundColor}`,
        lineHeight: lineHeight,
        clear: 'both',
      },
    },
    [
      header,
      ce(
        'form',
        {
          style: {
            boxSizing: 'border-box',
            padding: `${SPACING_HALF}px`,
            maxWidth: `${MAX_FORM_WIDTH}px`,
            margin: '0 auto',
            backgroundColor: formBackgroundColor,
          },
          onSubmit,
        },
        [
          ce(
            'p',
            {
              style: {
                boxSizing: 'border-box',
                padding: `0`,
                margin: '0',
                marginBottom: `${SPACING_QUARTER}px`,
                textAlign: 'center',
                fontFamily: formTitleFontFamily,
                fontSize: formTitleFontSize,
                fontWeight: formTitleFontWeight,
                color: formTitleTextColor,
                textTransform: formTitleTextTransform,
              },
            },
            [MAP_OF_BODY_TITLES[type]]
          ),
          ce(
            'p',
            {
              style: {
                boxSizing: 'border-box',
                padding: `0`,
                margin: '0',
                textAlign: 'center',
                fontFamily: formSubtitleFontFamily,
                fontSize: formSubtitleFontSize,
                fontWeight: formSubtitleFontWeight,
                color: formSubtitleTextColor,
                textTransform: formSubtitleTextTransform,
              },
            },
            [MAP_OF_SUBTITLES[type]]
          ),
          createFormGroup({
            labelText:
              type === 'budget-booster-page' ||
              type === 'budget-booster-page-cg'
                ? 'Current budget'
                : 'Property price',
            labelStyle,
            input: ce('input', {
              style: {
                ...COMMON_INPUT_STYLES,
                width: '50%',
                maxWidth: '50%',
              },
              value: values.price,
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                const price = serializePrice(event.currentTarget.value);

                event.currentTarget.value = price;
                values.price = price;
              },
            }),
            layout,
          }),
          createFormGroup({
            labelText: 'Who’s moving?',
            labelStyle,
            input: createSelect({
              options: PERSONS_OPTIONS,
              initialValue: values.persons,
              onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                values.persons = event.currentTarget.value;
                secondPerson.style.display =
                  values.persons === '2' ? 'block' : 'none';
              },
              layout,
            }),
            layout,
          }),
          ...(includeGender
            ? [
                createFormGroup({
                  labelText: 'Gender',
                  labelStyle,
                  input: createSelect({
                    options: GENDER_OPTIONS,
                    initialValue: values.gender1 || '',
                    onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                      values.gender1 = event.currentTarget.value;
                    },
                    layout,
                  }),
                  layout,
                }),
              ]
            : []),
          createFormGroup({
            labelText: 'Age (Years)',
            labelStyle,
            input: createSelect({
              options: AGE_OPTIONS,
              initialValue: values.age1,
              onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                values.age1 = event.currentTarget.value;
              },
              layout,
            }),
            layout,
          }),
          secondPerson,
          createButton({
            customStyles: {
              backgroundColor: formButtonColor,
              color: formButtonTextColor,
              fontSize: formButtonFontSize,
              width: '100%',
              maxWidth: '100%',
              marginBottom: `${SPACING_HALF}px`,
              textTransform: formButtonTextTransform,
              fontFamily: formButtonFontFamily,
              fontWeight: formButtonFontWeight,
            },
            hoverStyles: {
              backgroundColor: formButtonHoverColor,
              color: formButtonHoverTextColor,
            },
            text: 'Calculate',
            icon: RIGHT_CARET,
            layout,
          }),
          errors,
        ]
      ),
      footer,
    ]
  );

  return widget;
};

const createWidget = ({
  values,
  onSubmit,
  errors,
  styleOptions,
  type,
  includeGender,
  layout,
}: WidgetProps): HTMLDivElement => {
  if (layout === 'horizontal') {
    return createHorizontalWidget({
      values,
      onSubmit,
      errors,
      styleOptions,
      type,
      includeGender,
      layout,
    });
  }

  return createVerticalWidget({
    values,
    onSubmit,
    errors,
    styleOptions,
    type,
    includeGender,
    layout,
  });
};

export default createWidget;
