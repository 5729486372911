import { LOGO_ASPECT, PRIMARY, WHITE, SECONDARY } from './constants';

interface LogoProps {
  width: number;
  colored: boolean;
}

const createLogoHTML = ({
  width,
  colored,
}: LogoProps): string => `<a href="https://www.homewise.co.uk" target="_blank"><svg with="${width}" height="${Math.ceil(
  width * LOGO_ASPECT
)}" viewBox="0 0 584 277" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-labelledby="homewise-logo-title" role="img">
<title id="homewise-logo-title">Homewise</title>
<path fill="${
  colored ? PRIMARY : WHITE
}" d="M81.483,169.586 L81.483,106.501 C81.483,97.484 81.483,70.237 52.556,70.237 C34.325,70.237 21.967,83.018 21.967,106.291 L21.967,169.586 L0.163,169.586 L0.163,0.337 L21.967,0.337 L21.967,65.832 L22.375,65.832 C32.859,50.326 50.261,50.326 54.871,50.326 C72.888,50.326 87.555,58.079 95.946,71.079 C100.546,78.618 103.076,87.85 103.28,100.842 L103.28,169.586 L81.483,169.586"></path>
<path fill="${
  colored ? PRIMARY : WHITE
}" d="M175.837,70.237 C151.315,70.237 134.975,90.567 134.975,111.312 C134.975,132.276 151.534,152.603 175.837,152.603 C199.312,152.603 216.712,133.118 216.712,111.106 C216.712,90.567 200.369,70.237 175.837,70.237 M175.837,172.523 C136.866,172.523 113.166,140.036 113.166,110.68 C113.166,79.047 139.796,50.326 175.837,50.326 C211.06,50.326 238.502,77.999 238.502,111.106 C238.502,139.4 215.241,172.523 175.837,172.523"></path>
<path fill="${
  colored ? PRIMARY : WHITE
}" d="M400.468,143.414 L400.468,105.026 C400.468,95.174 399.215,70.237 373.023,70.237 C345.146,70.237 345.562,95.174 345.346,104.816 L345.346,143.414 L323.553,143.414 L323.553,105.026 C323.757,95.381 323.344,88.89 318.734,81.135 C313.282,72.758 305.113,70.237 297.149,70.237 C268.426,70.237 268.426,96.222 268.426,104.816 L268.426,143.414 L246.629,143.414 L246.629,53.477 L266.756,53.477 L266.756,66.045 L267.172,66.045 C271.364,61.23 278.916,50.326 300.498,50.326 C314.543,50.326 330.683,57.027 336.542,69.82 C340.32,63.955 348.914,50.326 374.904,50.326 C394.401,50.326 408.226,58.3 415.779,71.289 C419.959,78.832 422.274,88.263 422.274,99.581 L422.274,143.414 L400.468,143.414"></path>
<path fill="${
  colored ? PRIMARY : WHITE
}" d="M528.041,104.187 C526.984,87.213 511.479,70.237 490.523,70.237 C469.56,70.237 454.055,87.213 453.009,104.187 L528.041,104.187 Z M453.009,120.957 C458.451,143.387 476.483,152.603 491.147,152.603 C507.286,152.603 519.031,143.182 524.265,133.118 L546.062,133.118 C534.951,163.087 508.336,172.523 490.311,172.523 C457.398,172.523 431.201,144.223 431.201,111.312 C431.201,76.523 458.032,50.326 490.724,50.326 C522.165,50.326 549.618,75.896 549.83,109.847 C549.83,113.621 549.419,119.498 549.203,120.957 L453.009,120.957 L453.009,120.957 Z"></path>
<path fill="${
  colored ? SECONDARY : WHITE
}" d="M403.272,169.553 C403.618,168.705 403.784,167.855 403.784,166.828 C403.784,160.525 395.952,163.257 395.952,157.121 L395.952,154.047 L424.23,154.047 L424.23,157.121 C424.23,163.257 416.225,159.672 412.486,169.379 L371.584,276.383 L362.049,276.383 L333.594,195.628 L302.927,276.383 L293.561,276.383 L256.07,169.379 C252.662,159.672 245.163,163.257 245.163,157.121 L245.163,154.047 L281.636,154.047 L281.636,157.121 C281.636,162.906 273.796,160.692 273.796,166.828 C273.796,167.855 273.953,168.529 274.305,169.553 L302.42,254.059 L329.338,183.015 L324.562,169.379 C321.159,159.672 313.662,163.257 313.662,157.121 L313.662,154.047 L350.122,154.047 L350.122,157.121 C350.122,162.906 342.117,160.692 342.117,166.828 C342.117,167.683 342.279,168.529 342.617,169.553 L371.081,254.059 L403.272,169.553"></path>
<path fill="${
  colored ? SECONDARY : WHITE
}" d="M440.853,184.678 C440.853,187.177 438.761,187.437 436.79,188.619 C434.29,190.191 434.161,191.367 434.161,195.309 L434.161,261.685 C434.161,265.619 434.29,266.804 436.79,268.377 C438.761,269.689 440.853,269.82 440.853,272.31 L440.853,274.802 L414.359,274.802 L414.359,272.31 C414.359,269.82 416.458,269.689 418.421,268.377 C421.171,266.68 421.171,265.365 421.171,260.514 L421.171,196.488 C421.171,191.5 421.171,190.45 418.421,188.619 C416.587,187.437 414.359,187.305 414.359,184.678 L414.359,182.195 L440.853,182.195 L440.853,184.678"></path>
<path fill="${
  colored ? SECONDARY : WHITE
}" d="M451.087,249.756 L453.964,249.756 C456.725,249.756 457.386,253.423 459.214,257.754 C463.021,266.543 469.058,271.004 477.986,271.004 C489.53,271.004 497.921,263.141 497.921,252.762 C497.921,241.35 490.444,237.288 479.024,232.17 C462.235,224.697 453.964,218.656 453.964,204.494 C453.964,190.32 464.071,180.613 478.631,180.613 C488.087,180.613 492.415,185.34 496.085,185.34 C496.999,185.34 497.785,184.809 498.578,183.498 L503.563,183.498 L503.563,205.012 L501.071,205.012 C499.756,205.012 498.178,203.439 496.999,200.293 C493.457,190.845 489.129,185.732 480.076,185.732 C471.82,185.732 465.777,192.028 465.777,200.027 C465.777,211.443 476.271,213.543 488.601,219.443 C503.295,226.399 511.033,233.611 511.033,247.26 C511.033,263.254 498.314,276.383 479.024,276.383 C467.613,276.383 462.359,271.922 459.749,271.922 C457.9,271.922 456.993,272.578 456.328,274.413 L451.087,274.413 L451.087,249.756"></path>
<path fill="${
  colored ? SECONDARY : WHITE
}" d="M559.456,235.059 C558.409,231.115 557.1,229.158 553.161,229.158 L535.843,229.158 L535.843,268.377 L559.581,268.377 C568.372,268.377 572.697,266.416 575.456,260.773 C577.557,256.576 577.292,251.324 580.04,251.192 L583.727,250.926 L583.727,274.802 L516.04,274.802 L516.04,272.31 C516.04,269.82 518.124,269.689 520.099,268.377 C522.853,266.68 522.853,265.365 522.853,260.514 L522.853,196.488 C522.853,191.5 522.853,190.45 520.099,188.619 C518.264,187.437 516.04,187.305 516.04,184.678 L516.04,182.195 L566.008,182.195 C570.612,182.195 574.936,181.534 578.599,180.613 L578.599,205.012 L575.584,204.754 C572.042,204.623 571.918,200.293 569.294,195.567 C566.269,190.061 562.993,188.619 553.811,188.619 L535.843,188.619 L535.843,222.328 L553.161,222.328 C557.1,222.328 558.282,220.891 559.456,217.879 C560.238,215.767 560.896,214.588 562.08,214.588 L565.231,214.588 L565.231,239.519 L562.08,239.519 C561.024,239.519 560.238,238.076 559.456,235.059"></path>
<path fill="${
  colored ? PRIMARY : WHITE
}" d="M562.452,39.123 C563.652,39.123 564.551,38.828 565.151,38.238 C565.75,37.648 566.051,36.857 566.051,35.867 C566.051,34.819 565.727,34.006 565.079,33.425 C564.432,32.843 563.536,32.553 562.395,32.553 L558.795,32.553 L558.795,39.123 L562.452,39.123 Z M556.139,30.183 L562.223,30.183 C563.442,30.183 564.47,30.349 565.308,30.682 C566.146,31.016 566.827,31.444 567.351,31.968 C567.874,32.492 568.255,33.087 568.493,33.753 C568.731,34.419 568.85,35.095 568.85,35.781 C568.85,37 568.565,38.076 567.993,39.009 C567.422,39.942 566.536,40.637 565.337,41.095 L569.793,49.236 L566.707,49.236 L562.623,41.437 L558.795,41.437 L558.795,49.236 L556.139,49.236 L556.139,30.183 L556.139,30.183 Z"></path>
<path fill="${
  colored ? PRIMARY : WHITE
}" d="M562.115,25.66 C554.367,25.66 548.065,31.962 548.065,39.709 C548.065,47.456 554.367,53.758 562.115,53.758 C569.861,53.758 576.164,47.456 576.164,39.709 C576.164,31.962 569.861,25.66 562.115,25.66 Z M562.115,55.345 C553.492,55.345 546.478,48.331 546.478,39.709 C546.478,31.087 553.492,24.073 562.115,24.073 C570.737,24.073 577.751,31.087 577.751,39.709 C577.751,48.331 570.737,55.345 562.115,55.345 L562.115,55.345 Z"></path>
</svg></a>`;

export default createLogoHTML;
