import { CalculatorType, Gender } from './types';
import { CSSStyle } from './create-element';

export const DISCOUNT_MAP = {
  60: { male: 22.0, female: 19.0 },
  61: { male: 23.0, female: 20.0 },
  62: { male: 24.0, female: 21.0 },
  63: { male: 25.0, female: 22.0 },
  64: { male: 26.0, female: 24.0 },
  65: { male: 27.5, female: 25.0 },
  66: { male: 29.0, female: 26.5 },
  67: { male: 30.5, female: 28.0 },
  68: { male: 32.0, female: 29.0 },
  69: { male: 34.0, female: 31.0 },
  70: { male: 35.0, female: 32.5 },
  71: { male: 37.0, female: 34.5 },
  72: { male: 39.0, female: 36.0 },
  73: { male: 40.0, female: 37.0 },
  74: { male: 41.0, female: 39.0 },
  75: { male: 42.5, female: 40.0 },
  76: { male: 44.0, female: 41.0 },
  77: { male: 45.0, female: 42.0 },
  78: { male: 46.0, female: 43.0 },
  79: { male: 48.0, female: 44.0 },
  80: { male: 49.0, female: 46.0 },
  81: { male: 50.0, female: 47.0 },
  82: { male: 51.0, female: 48.0 },
  83: { male: 52.0, female: 49.0 },
  84: { male: 53.0, female: 50.0 },
  85: { male: 54.0, female: 51.0 },
  86: { male: 55.0, female: 52.0 },
  87: { male: 56.0, female: 53.0 },
  88: { male: 57.0, female: 54.0 },
  89: { male: 58.0, female: 55.0 },
  90: { male: 59.0, female: 56.0 },
};

export const DISCOUNT_MAP_RETIREMENT_WIDGET = {
  60: 9,
  61: 10,
  62: 11,
  63: 12,
  64: 14,
  65: 15,
  66: 16.5,
  67: 18,
  68: 19,
  69: 21,
  70: 22.5,
  71: 24.5,
  72: 26,
  73: 27,
  74: 29,
  75: 30,
  76: 31,
  77: 32,
  78: 33,
  79: 34,
  80: 36,
  81: 37,
  82: 38,
  83: 39,
  84: 40,
  85: 41,
  86: 42,
  87: 43,
  88: 44,
  89: 45,
  90: 46,
};

export const LOWER_COUPLES_DISCOUNT = 2;
export const DEFAULT_GENDER_FOR_CALCULATIONS_IF_NOT_SET: Gender = 'female';

export const REDIRECT_URL = 'https://www.homewise.co.uk/calculator/';

export const EXTERNAL_HREF = 'www.homewise.co.uk';

export const MATCHES_INVALID_NUMBER_CHARS = /[^0-9.]/gi;
export const MATCHES_CURRENCY_SEPARATOR = /\B(?=(\d{3})+(?!\d))/gi;

export const VALID_PERSONS_OPTIONS = ['1', '2'];
export const INITIAL_VALID_PERSONS_OPTIONS = [''].concat(VALID_PERSONS_OPTIONS);
export const VALID_GENDER_OPTIONS = ['male', 'female'];
export const INITIAL_VALID_GENDER_OPTIONS = [''].concat(VALID_GENDER_OPTIONS);

export const VALID_AGE_OPTIONS = Object.keys(DISCOUNT_MAP);
export const INITIAL_VALID_AGE_OPTIONS = [''].concat(VALID_AGE_OPTIONS);

export const BUDGET_BOOSTER_PAGE = 'budget-booster-page';
export const BUDGET_BOOSTER_PAGE_CG = 'budget-booster-page-cg';
export const BUDGET_BOOSTER_PAGE_ARUN = 'budget-booster-page-arun';
export const CALCULATOR_PAGE_RETIREMENT = 'hw-calculator-page-retirement';

export const PERSONS_OPTIONS = [
  { label: '', value: '' },
  { label: '1 Person', value: '1' },
  { label: '2 People', value: '2' },
];

export const GENDER_OPTIONS = [
  { label: '', value: '' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

export const AGE_OPTIONS = [{ label: '', value: '' }].concat(
  Object.keys(DISCOUNT_MAP).map(option => ({
    label: option,
    value: option,
  }))
);

export const PRIMARY = '#7CC5AD';
export const PRIMARY_SHADE = '#73B7A1';
export const PEACH = '#EF7B49';
export const PEACH_SHADE = '#DA6D3E';
export const SECONDARY = '#6F445E';
export const ERROR = '#BF0249';
export const WHITE = '#fff';
export const GREY_DARK = '#3C3C3B';
const GREY_LIGHT = '#BFBFBF';

export const CALIBRI =
  'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif';

export const DEFAULT_MODAL_Z_INDEX = '10000';

export const SMALL_LINE_HEIGHT = '1.1em';
export const DEFAULT_LINE_HEIGHT = '1.5em';
export const REDUCED_LINE_HEIGHT = '1.2em';

const BORDER_RADIUS = 5;
export const SPACING = 24;
export const SPACING_HALF = SPACING / 2;
export const SPACING_QUARTER = SPACING / 4;

export const BUTTON_SPACE_FOR_ICON = 42;
export const BUTTON_ICON_SIZE = 20;

export const BUDGET_WIDGET_TITLE_FONT_SIZE = 35;
export const BUDGET_MODAL_HEADER_TITLE_FONT_SIZE = 26;

export const WIDGET_TITLE_FONT_SIZE = 24;
// eslint-disable-next-line import/no-unused-modules
export const WIDGET_TITLE_FONT_SIZE_BOOSTER_LISTING_CG = 18;
export const DEFAULT_BUTTON_FONT_SIZE = 22;
export const FONT_SIZE_TITLE = 24;
export const FONT_SIZE_LARGE_EM = '1.8em';
export const LINE_HEIGHT_LARGE_EM = '1em';

export const POWERED_BY_WIDTH = 80;
export const HORIZONTAL_POWERED_BY_WIDTH = 80;
export const LOGO_WIDTH = 130;
export const HORIZONTAL_LOGO_WIDTH = 90;
export const LOGO_ASPECT = 0.47;
export const MAX_FORM_WIDTH = 450;

export const LOGO_WIDTH_MODAL = 100;

export const DEFAULT_MOBILE_MEDIA_MAX_WIDTH = 300;

export const SELECT_TRIANGLE =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxM3B4IiB2aWV3Qm94PSIwIDAgMTYgMTMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiBmaWxsPSIjNkY2RjZGIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAwMDAwMCwgNi4xMTUyMzQpIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTguMDAwMDAwLCAtNi4xMTUyMzQpICIgcG9pbnRzPSI4IC01LjY4NDM0MTg5ZS0xNCAxNiAxMi4yMzA0Njg3IDAgMTIuMjMwNDY4NyI+PC9wb2x5Z29uPgo8L3N2Zz4K';

export const MODAL_CLOSE_HTML =
  '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>';

export const RIGHT_CARET = `<svg xmlns="http://www.w3.org/2000/svg" width="${BUTTON_ICON_SIZE}" height="${BUTTON_ICON_SIZE}" viewBox="0 0 192 512"><path d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>`;

const DOWN_ARROW =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMzMCAzMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGlkPSJYTUxJRF8yMjVfIiBkPSJNMzI1LjYwNyw3OS4zOTNjLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFsLTEzOS4zOSwxMzkuMzkzTDI1LjYwNyw3OS4zOTMNCgljLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFjLTUuODU4LDUuODU4LTUuODU4LDE1LjM1NSwwLDIxLjIxM2wxNTAuMDA0LDE1MGMyLjgxMywyLjgxMyw2LjYyOCw0LjM5MywxMC42MDYsNC4zOTMNCglzNy43OTQtMS41ODEsMTAuNjA2LTQuMzk0bDE0OS45OTYtMTUwQzMzMS40NjUsOTQuNzQ5LDMzMS40NjUsODUuMjUxLDMyNS42MDcsNzkuMzkzeiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=';

export const COMMON_INPUT_STYLES: CSSStyle = {
  boxSizing: 'border-box',
  display: 'inline-block',
  minWidth: 'inherit',
  verticalAlign: 'middle',
  margin: '0',
  padding: `0 0 0 ${SPACING_HALF}px`,
  fontSize: '18px',
  lineHeight: '1.6em',
  height: '32px',
  borderRadius: `${BORDER_RADIUS}px`,
  webkitAppearance: 'none',
  border: `1px solid ${GREY_LIGHT}`,
  backgroundColor: WHITE,
  color: GREY_DARK,
  fontFamily: CALIBRI,
};

export const HORIZONTAL_INPUT_STYLES: CSSStyle = {
  width: '100%',
  maxWidth: '100%',
  backgroundImage: `url(${DOWN_ARROW})`,
};

export const COMMON_BUTTON_STYLES: CSSStyle = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  textAlign: 'center',
  cursor: 'pointer',
  margin: '0',
  border: 'none',
  boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.5)',
  padding: `${SPACING_HALF}px ${BUTTON_SPACE_FOR_ICON}px`,
  borderRadius: `${BORDER_RADIUS}px`,
  transition: 'ease-in-out 0.1s all',
};

export const HORIZONTAL_BUTTON_STYLES: CSSStyle = {
  padding: `${SPACING_HALF}px 52px`,
  boxShadow: 'none',
  borderRadius: '7px',
  width: 'auto',
};

export const COMMON_BUTTON_HOVER_STYLES: CSSStyle = {
  boxShadow: '3px 3px 5px 0 rgba(0,0,0,0.5)',
};

export const CALCULATOR_TYPES: ReadonlyArray<CalculatorType> = [
  'listing',
  'page',
  'budget-booster-listing',
  'budget-booster-listing-cg',
  'budget-booster-listing-arun',
  'hw-calculator-listing-retirement',
  'hw-calculator-page-retirement',
  'budget-booster-page',
  'budget-booster-page-cg',
  'budget-booster-page-arun',
];

export const PAGE_CALCULATOR_TYPES: ReadonlyArray<CalculatorType> = [
  'page',
  'budget-booster-page',
  'budget-booster-page-cg',
  'budget-booster-page-arun',
];

export const PAGE_TYPE_QUERY_ORDER = [
  'type',
  'budget',
  'persons',
  'age1',
  'age2',
  'maxprice',
  'boost',
  'referrer',
  'calculator',
];

export const LIST_TYPE_QUERY_ORDER = [
  'type',
  'propertyprice',
  'persons',
  'age1',
  'age2',
  'cost',
  'saving',
  'referrer',
  'calculator',
];
