import { ResizeObserver } from '@juggle/resize-observer';

function resizeContentObserver(
  mobileMediaMaxWidth: number,
  target: Element
): void {
  const resizeObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      const { width } = entry.contentRect;

      const labels = target.getElementsByTagName('label');

      const selects = target.getElementsByTagName('select');

      const inputs = target.getElementsByTagName('input');

      const buttons = target.getElementsByTagName('button');

      const horizontalWidgetFooter = target.getElementsByClassName(
        'horizontal-widget-footer'
      );

      const horizontalWidgetLogo = target.getElementsByClassName(
        'horizontal-widget-logo'
      );

      const horizontalWidgetForm = target.getElementsByClassName(
        'horizontal-widget-form'
      );

      const horizontalWidgetFormGroup = target.getElementsByClassName(
        'horizontal-widget-form-group'
      );

      const horizontalWidgetHeader = target.getElementsByClassName(
        'horizontal-widget-header'
      );

      const horizontalWidgetSubHeader = target.getElementsByClassName(
        'horizontal-widget-sub-header'
      );

      if (labels || selects || buttons) {
        [
          ...[].slice.call(labels),
          ...[].slice.call(selects),
          ...[].slice.call(inputs),
        ].map((element: HTMLElement) => {
          if (element) {
            if (width < mobileMediaMaxWidth) {
              element.style.width = '100%';
              element.style.maxWidth = '100%';
            } else if (horizontalWidgetFormGroup.length <= 0) {
              element.style.width = '50%';
              element.style.maxWidth = '50%';
            }
          }
        });
      }

      if (horizontalWidgetFooter || horizontalWidgetForm) {
        [
          ...[].slice.call(horizontalWidgetFooter),
          ...[].slice.call(horizontalWidgetForm),
        ].map((element: HTMLElement) => {
          if (element) {
            if (width < mobileMediaMaxWidth) {
              element.style.flexDirection = 'column';
            } else {
              element.style.flexDirection = 'row';
            }
          }
        });
      }

      if (horizontalWidgetHeader || horizontalWidgetSubHeader) {
        [
          ...[].slice.call(horizontalWidgetHeader),
          ...[].slice.call(horizontalWidgetSubHeader),
        ].map((element: HTMLElement) => {
          if (element) {
            if (width < mobileMediaMaxWidth) {
              element.style.textAlign = 'center';
            } else {
              element.style.textAlign = 'left';
            }
          }
        });
      }

      if (horizontalWidgetLogo) {
        [...[].slice.call(horizontalWidgetLogo)].map((element: HTMLElement) => {
          if (element) {
            if (width < mobileMediaMaxWidth) {
              element.style.textAlign = 'center';
              element.style.marginTop = '10px';
            } else {
              element.style.textAlign = 'left';
              element.style.marginTop = '0';
            }
          }
        });
      }
    });
  });

  if (target) {
    resizeObserver.observe(target);
  } else {
    resizeObserver.disconnect();
  }
}

export default resizeContentObserver;
