import {
  CalculatorType,
  SerializedValues1Person,
  SerializedValues2Person,
} from './types';
import {
  DISCOUNT_MAP,
  LOWER_COUPLES_DISCOUNT,
  DISCOUNT_MAP_RETIREMENT_WIDGET,
  DEFAULT_GENDER_FOR_CALCULATIONS_IF_NOT_SET,
} from './constants';

const findDiscount = (
  values: SerializedValues1Person | SerializedValues2Person,
  type: CalculatorType
): number => {
  const gender1 = values.gender1 || DEFAULT_GENDER_FOR_CALCULATIONS_IF_NOT_SET;

  const discount1 =
    type === 'hw-calculator-listing-retirement' ||
    type === 'hw-calculator-page-retirement'
      ? DISCOUNT_MAP_RETIREMENT_WIDGET[values.age1]
      : DISCOUNT_MAP[values.age1][gender1];

  if (values.persons === 1) {
    return discount1 / 100;
  }

  const gender2 = values.gender2 || DEFAULT_GENDER_FOR_CALCULATIONS_IF_NOT_SET;

  const discount2 =
    type === 'hw-calculator-listing-retirement' ||
    type === 'hw-calculator-page-retirement'
      ? DISCOUNT_MAP_RETIREMENT_WIDGET[values.age2]
      : DISCOUNT_MAP[values.age2][gender2];

  return (Math.min(discount1, discount2) - LOWER_COUPLES_DISCOUNT) / 100;
};

export default findDiscount;
