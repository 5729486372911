/* global process */

// DO NOT CHANGE THIS LINE - it must be written as such in order for envify to load the variable
const GA_ID = process.env.GA_ID;
const ANALYTICS_SCRIPT_SRC = 'https://www.google-analytics.com/analytics.js';
export const GA_COMMAND = 'homewiseCalculatorWidget';

const gaGlobal = window.ga as UniversalAnalytics.ga | undefined;

const configureGaScript = document.createElement('script');

configureGaScript.innerHTML = `
window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
ga('create', '${GA_ID}', 'auto', '${GA_COMMAND}');
ga('${GA_COMMAND}.send', 'pageview');
`;
document.head.appendChild(configureGaScript);

// istanbul ignore else
if (
  !gaGlobal &&
  ![...document.getElementsByTagName('script')].find(
    el => el.src === ANALYTICS_SCRIPT_SRC
  )
) {
  const analyticsScript = document.createElement('script');
  analyticsScript.src = ANALYTICS_SCRIPT_SRC;
  analyticsScript.async = true;
  document.head.appendChild(analyticsScript);
}

export const isInitialized = (
  ga: UniversalAnalytics.ga | undefined
): ga is UniversalAnalytics.ga => Boolean(ga && ga.create);
