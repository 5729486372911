import ce, { ChangeEvent } from './create-element';
import {
  SELECT_TRIANGLE,
  COMMON_INPUT_STYLES,
  HORIZONTAL_INPUT_STYLES,
} from './constants';
import { LayoutType } from './types';

interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  options: readonly SelectOption[];
  initialValue: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  layout: LayoutType;
}

const createSelect = ({
  options,
  initialValue,
  onChange,
  layout,
}: SelectProps): HTMLSelectElement =>
  ce(
    'select',
    {
      style: {
        ...COMMON_INPUT_STYLES,
        backgroundImage: `url(${SELECT_TRIANGLE})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '95% 50%',
        backgroundSize: '12px auto',
        width: '50%',
        maxWidth: '50%',
        ...(layout === 'horizontal' ? HORIZONTAL_INPUT_STYLES : {}),
      },
      onChange,
    },
    options.map(({ label, value }) =>
      ce(
        'option',
        {
          value,
          selected: value === initialValue ? 'selected' : undefined,
        },
        label
      )
    )
  );

export default createSelect;
