import { CalculatorStyleOptions, CalculatorType } from './types';

interface BodyContentProps {
  type: CalculatorType;
  styleOptions: CalculatorStyleOptions;
}

const createModalBodyBottomContent = ({ type }: BodyContentProps): string => {
  const getBodyBottomText = () => {
    switch (type) {
      default:
        return ``;
    }
  };

  return `
    ${getBodyBottomText()}
  `;
};

export default createModalBodyBottomContent;
