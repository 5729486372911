import * as queryString from 'query-string';

import { Values, CalculatorOptions } from './types';
import { getQueryStringByType } from './utils';
import {
  CALCULATOR_TYPES,
  DEFAULT_MOBILE_MEDIA_MAX_WIDTH,
  EXTERNAL_HREF,
  REDIRECT_URL,
} from './constants';
import { serializeValues, serializeInitialValues } from './serialize';
import findDiscount from './find-discount';
import createModalBodyContent from './create-modal-body-content';
import createModalBodyBottomContent from './create-modal-body-bottom-content';
import createError from './create-error';
import createModal from './create-modal';
import createWidget from './create-widget';
import { getStyleOptionsWithDefaults, getCalculatorConfigFor } from './options';
import { isInitialized, GA_COMMAND } from './google-analytics';
import resizeContentObserver from './resize-content-observer';

const EVENT_CATEGORY = 'partner_calculator_widget';

const render = (options: Partial<CalculatorOptions>) => {
  const {
    targetSelector,
    initialValues,
    type = 'page',
    mobileMediaMaxWidth = DEFAULT_MOBILE_MEDIA_MAX_WIDTH,
    companyName,
  } = options;

  const styleOptions = getStyleOptionsWithDefaults(options);
  const { includeGender, layout } = getCalculatorConfigFor(type);

  if (CALCULATOR_TYPES.findIndex(calcType => calcType === type) === -1) {
    throw new Error(
      `Wrong widget type "${type}" passed.\nShould be one of: [${CALCULATOR_TYPES.join(
        ', '
      )}]`
    );
  }

  if (!targetSelector) {
    throw new Error('No "targetSelector" provided');
  }

  const target = document.querySelector(targetSelector);

  if (!target) {
    throw new Error(
      `Could not find target element from selector "${targetSelector}"`
    );
  }

  const values: Values = serializeInitialValues(initialValues, includeGender);

  resizeContentObserver(mobileMediaMaxWidth, target);

  const redirect = () => {
    const result = serializeValues(values, includeGender);
    const query = getQueryStringByType(values, type, result);

    const url = `${REDIRECT_URL}?${query}`;

    // Need to open here as safari does not allow asynchronous opens of new tabs
    if (options.openInNewTab) {
      window.open(url, '_blank');
    }
    // We need to check if ga is loaded as it might have been blocked by adblockers
    if (isInitialized(window.ga)) {
      window.ga(`${GA_COMMAND}.send`, {
        hitType: 'event',
        hitCallback: !options.openInNewTab
          ? () => window.location.assign(url)
          : undefined,
        eventCategory: EVENT_CATEGORY,
        eventAction: 'click',
        eventLabel: `button:modal, type:${type}, url:${window.location.href}`,
      });
    } else if (!options.openInNewTab) {
      window.location.assign(url);
    }
  };

  const { modalOverlay, modalBody, modalBodyBottom } = createModal({
    callback: redirect,
    styleOptions,
    type,
    companyName,
  });

  const errors = document.createElement('div');
  errors.style.display = 'none';

  const onSubmit = (event: Event) => {
    event.preventDefault();
    const result = serializeValues(values, includeGender);

    errors.innerHTML = '';

    if ('errors' in result) {
      errors.style.display = 'block';
      result.errors.forEach(text => {
        errors.appendChild(createError({ text, styleOptions }));
      });
    } else {
      errors.style.display = 'none';

      const discount = findDiscount(result.values, type);

      if (isInitialized(window.ga)) {
        window.ga(`${GA_COMMAND}.send`, {
          hitType: 'event',
          eventCategory: EVENT_CATEGORY,
          eventAction: 'click',
          eventLabel: `button:widget, type:${type}, url:${window.location.href}`,
        });
      }

      modalOverlay.style.display = 'block';
      modalBody.innerHTML = createModalBodyContent({
        values: result.values,
        discount,
        type,
        styleOptions,
      });
      modalBodyBottom.innerHTML = createModalBodyBottomContent({
        type,
        styleOptions,
      });

      const query = queryString.stringify({
        ...values,
        referrer: window.location.href,
        calculator: true,
      });

      const anchors = modalBodyBottom.getElementsByTagName('a');

      for (const anchor of anchors) {
        if (anchor.href.includes(EXTERNAL_HREF)) {
          anchor.addEventListener(
            'click',
            function() {
              event.preventDefault();

              window.ga(`${GA_COMMAND}.send`, {
                hitType: 'event',
                eventCategory: EVENT_CATEGORY,
                eventAction: 'click',
                eventLabel: `link:modal, type:${type}, url:${window.location.href}`,
              });

              const urlWithQuery = `${anchor.href}?${query}`;

              if (anchor.target === '_blank') {
                window.open(urlWithQuery);
              } else {
                window.location.assign(urlWithQuery);
              }
            },
            false
          );
        }
      }

      const modalFooters = document.getElementsByClassName('modal-footer');

      for (const modalFooter of modalFooters) {
        const logoLinks = modalFooter.getElementsByTagName('a');
        for (const logoLink of logoLinks) {
          if (logoLink.href.includes(EXTERNAL_HREF)) {
            logoLink.addEventListener(
              'click',
              function() {
                event.preventDefault();

                window.ga(`${GA_COMMAND}.send`, {
                  hitType: 'event',
                  eventCategory: EVENT_CATEGORY,
                  eventAction: 'click',
                  eventLabel: `logo:modal, type:${type}, url:${window.location.href}`,
                });

                const urlWithQuery = `${logoLink.href}?${query}`;

                if (logoLink.target === '_blank') {
                  window.open(urlWithQuery);
                } else {
                  window.location.assign(urlWithQuery);
                }
              },
              false
            );
          }
        }
      }
    }

    /* istanbul ignore else */
    if (typeof options.onSubmit === 'function') {
      options.onSubmit();
    }
  };

  const widget = createWidget({
    values,
    onSubmit,
    errors,
    styleOptions,
    type,
    includeGender,
    layout,
  });

  target.appendChild(widget);
  document.body.appendChild(modalOverlay);
};

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    renderHomewiseCalculator: typeof render;
  }
}

window.renderHomewiseCalculator = render;
