import * as queryString from 'query-string';

import {
  MATCHES_CURRENCY_SEPARATOR,
  MATCHES_INVALID_NUMBER_CHARS,
  PAGE_CALCULATOR_TYPES,
  LIST_TYPE_QUERY_ORDER,
  PAGE_TYPE_QUERY_ORDER,
  BUDGET_BOOSTER_PAGE,
  BUDGET_BOOSTER_PAGE_CG,
  BUDGET_BOOSTER_PAGE_ARUN,
  CALCULATOR_PAGE_RETIREMENT,
} from './constants';
import findDiscount from './find-discount';
import {
  CalculatorType,
  Values,
  QueryTypeValues,
  SerializerResultErrors,
  SerializerResultValues,
} from './types';

export const formatCurrency = (value: number): string => {
  return `£${value.toFixed().replace(MATCHES_CURRENCY_SEPARATOR, ',')}`;
};

const isPageType = (type: CalculatorType): boolean => {
  return PAGE_CALCULATOR_TYPES.indexOf(type) >= 0 ? true : false;
};

export const getMaxPropertyValue = (
  type: CalculatorType,
  price: number,
  discount: number
): string => {
  return type === BUDGET_BOOSTER_PAGE ||
    type === BUDGET_BOOSTER_PAGE_CG ||
    type === BUDGET_BOOSTER_PAGE_ARUN ||
    type === CALCULATOR_PAGE_RETIREMENT
    ? formatCurrency(price / (1 - discount))
    : formatCurrency(price - price * discount);
};

export const getBoostAmount = (
  type: CalculatorType,
  price: number,
  discount: number
): string => {
  return type === BUDGET_BOOSTER_PAGE ||
    type === BUDGET_BOOSTER_PAGE_CG ||
    type === BUDGET_BOOSTER_PAGE_ARUN ||
    type === CALCULATOR_PAGE_RETIREMENT
    ? formatCurrency(price * (discount / (1 - discount)))
    : formatCurrency(price * discount);
};

const getPageTypeQueryValues = (
  values: Values,
  type: CalculatorType,
  finalPrice: string,
  finalSavings: string
): QueryTypeValues => {
  return {
    type,
    budget: values.price,
    persons: values.persons,
    age1: values.age1,
    age2: values.age2,
    maxprice: finalPrice,
    boost: finalSavings,
    ...(type === 'page'
      ? {}
      : { gender1: values.gender1, gender2: values.gender2 }),
  };
};

const getListingTypeQuery = (
  values: Values,
  type: CalculatorType,
  finalPrice: string,
  finalSavings: string
): QueryTypeValues => {
  return {
    type,
    cost: finalPrice,
    saving: finalSavings,
    propertyprice: values.price,
    persons: values.persons,
    gender1: values.gender1,
    age1: values.age1,
    gender2: values.gender2,
    age2: values.age2,
  };
};

export const getQueryStringByType = (
  values: Values,
  type: CalculatorType,
  result: SerializerResultErrors | SerializerResultValues
): string => {
  let finalPrice = '';
  let finalSavings = '';

  if (!('errors' in result)) {
    const discount = findDiscount(result.values, type);
    const priceNumber = parseFloat(
      values.price.replace(MATCHES_INVALID_NUMBER_CHARS, '')
    );
    finalPrice = getMaxPropertyValue(type, priceNumber, discount);
    finalSavings = getBoostAmount(type, priceNumber, discount);
  }

  const sortingOrder = isPageType(type)
    ? PAGE_TYPE_QUERY_ORDER
    : LIST_TYPE_QUERY_ORDER;

  const queryValues = isPageType(type)
    ? getPageTypeQueryValues(values, type, finalPrice, finalSavings)
    : getListingTypeQuery(values, type, finalPrice, finalSavings);

  return queryString.stringify(
    {
      ...queryValues,
      referrer: window.location.href,
      calculator: true,
    },
    {
      sort: (a, b) => sortingOrder.indexOf(a) - sortingOrder.indexOf(b),
    }
  );
};
