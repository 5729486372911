import ce, { CSSStyle } from './create-element';
import {
  COMMON_BUTTON_STYLES,
  COMMON_BUTTON_HOVER_STYLES,
  BUTTON_SPACE_FOR_ICON,
  BUTTON_ICON_SIZE,
  HORIZONTAL_BUTTON_STYLES,
} from './constants';
import { LayoutType } from './types';

function addFillStyle(style: CSSStyle): CSSStyle {
  return { ...style, fill: style.color };
}

interface ButtonProps {
  customStyles: CSSStyle;
  hoverStyles: CSSStyle;
  text: string;
  icon?: string;
  onClick?: (event: MouseEvent) => void;
  layout: LayoutType;
}

const createButton = ({
  customStyles,
  hoverStyles,
  onClick,
  text,
  icon,
  layout,
}: ButtonProps): HTMLButtonElement => {
  const style = addFillStyle({
    ...COMMON_BUTTON_STYLES,
    ...customStyles,
    ...(layout === 'horizontal' ? HORIZONTAL_BUTTON_STYLES : {}),
  });

  const allHoverStyles = addFillStyle({
    ...COMMON_BUTTON_HOVER_STYLES,
    ...hoverStyles,
  });

  const iconChildren = icon
    ? [
        ce('div', {
          style: {
            position: 'absolute',
            right: `${(BUTTON_SPACE_FOR_ICON - 20) * 0.5}px`,
            top: '50%',
            transform: 'translate(0, -50%)',
            width: `${BUTTON_ICON_SIZE}px`,
            height: `${BUTTON_ICON_SIZE}px`,
          },
          innerHTML: icon,
        }),
      ]
    : [];
  const children: readonly (string | HTMLDivElement)[] = [
    text,
    ...iconChildren,
  ];

  const element = ce(
    'button',
    {
      style: { ...style, position: 'relative' },
      onClick,
      onMouseOver: event => {
        (Object.keys(allHoverStyles) as (keyof CSSStyle)[]).forEach(key => {
          const value = allHoverStyles[key];
          if (typeof value !== 'undefined') {
            event.currentTarget.style[key] = value;
          }
        });
      },
      onMouseOut: event => {
        (Object.keys(allHoverStyles) as (keyof CSSStyle)[]).forEach(key => {
          const previousValue = style[key];
          event.currentTarget.style[key] = '';

          if (typeof previousValue !== 'undefined') {
            event.currentTarget.style[key] = previousValue;
          }
        });
      },
    },
    children
  );

  return element;
};

export default createButton;
