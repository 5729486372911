import { LOGO_ASPECT } from './constants';

interface HomeIconLogo {
  width: number;
  color: string;
}

const createBoosterIconLogoHTML = ({
  width,
  color,
}: HomeIconLogo): string => `<svg with="${width}" height="${Math.ceil(
  width * LOGO_ASPECT
)}" x="0px" y="0px" viewBox="556.7999877929688 582.2000122070312 807.7999877929688 807.8999633789062" style="enable-background:new 0 0 1800 1800;" xml:space="preserve">
<path fill="${color}" d="M1332.9,828.9c-20.3-48.1-49.5-91.3-86.6-128.4c-37.1-37.1-80.3-66.2-128.4-86.6c-49.8-21.1-102.7-31.7-157.2-31.7  S853.3,592.9,803.5,614c-48.1,20.3-91.3,49.5-128.4,86.6c-37.1,37.1-66.2,80.3-86.6,128.4c-21.1,49.8-31.7,102.7-31.7,157.2  s10.7,107.4,31.7,157.2c20.3,48.1,49.5,91.3,86.6,128.4c37.1,37.1,80.3,66.2,128.4,86.6c49.8,21.1,102.7,31.7,157.2,31.7  s107.4-10.7,157.2-31.7c48.1-20.3,91.3-49.5,128.4-86.6c37.1-37.1,66.2-80.3,86.6-128.4c21.1-49.8,31.7-102.7,31.7-157.2  S1354,878.7,1332.9,828.9z M1309.9,1133.6c-19.1,45.1-46.4,85.6-81.2,120.4s-75.3,62.1-120.4,81.2c-46.7,19.8-96.3,29.8-147.5,29.8  s-100.8-10-147.5-29.8c-37.2-15.7-71.3-37.1-101.6-63.6L863.4,1120l173,173l123.2-123.2l17.4,65.5l72.8-173.4l-173.4,72.8l65.5,17.4  l-105.5,105.5l-173-173l-170,170c-0.2-0.2-0.4-0.4-0.6-0.6c-34.8-34.8-62.1-75.3-81.2-120.4c-19.8-46.7-29.8-96.3-29.8-147.5  s10-100.8,29.8-147.5c19.1-45.1,46.4-85.6,81.2-120.4s75.3-62.1,120.4-81.2c46.7-19.8,96.3-29.8,147.5-29.8s100.8,10,147.5,29.8  c45.1,19.1,85.6,46.4,120.4,81.2s62.1,75.3,81.2,120.4c19.8,46.7,29.8,96.3,29.8,147.5S1329.7,1086.9,1309.9,1133.6z"></path>
<g>
<path fill="${color}" d="M813.3,1020.2l43.7-10.9V890.8h-43.7v-35.9h43.7v-56.1c0-38.5,11.4-71.2,32.7-92.5c19.8-19.8,47.8-31.2,82.1-31.2   c55.1,0,86.8,24.4,111.2,56.1l-31.7,24.9c-20.3-25.5-42.1-43.1-80-43.1c-21.3,0-37.9,6.8-50.9,19.2c-14.6,15.1-22.3,37.9-22.3,66.5   v56.1h145v35.9H898v116.9h184.5v36.4H813.3V1020.2z"></path>
</g>
</svg>`;

export default createBoosterIconLogoHTML;
