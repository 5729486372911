import {
  FONT_SIZE_LARGE_EM,
  FONT_SIZE_TITLE,
  SPACING_HALF,
  REDUCED_LINE_HEIGHT,
  LINE_HEIGHT_LARGE_EM,
} from './constants';
import {
  SerializedValues1Person,
  SerializedValues2Person,
  CalculatorStyleOptions,
  CalculatorType,
} from './types';
import { formatCurrency, getBoostAmount, getMaxPropertyValue } from './utils';
import { CSSStyle } from './create-element';

interface BodyContentProps {
  values: SerializedValues1Person | SerializedValues2Person;
  discount: number;
  styleOptions: CalculatorStyleOptions;
  type: CalculatorType;
}

// Putting this in here as we've needed to adjust the spacing specifically for this section several times
const SPACING_MULTIPLIER = 1.5;

const createModalBodyContent = ({
  values,
  discount,
  type,
  styleOptions: {
    modalBodyTitleFontFamily,
    modalBodyTitleFontSize,
    modalBodyTitleFontWeight,
    modalBodyTitleTextColor,
    modalBodyTitleTextTransform,
    modalBodyFontFamily,
    modalBodyFontSize,
    modalBodyFontWeight,
    modalBodyTextColor,
  },
}: BodyContentProps): string => {
  const { price } = values;
  const formattedPrice = formatCurrency(price);
  const formattedSaving = getBoostAmount(type, price, discount);

  const formattedFinalPrice = getMaxPropertyValue(type, price, discount);

  const bodyTextStyle: CSSStyle = `color: ${modalBodyTextColor}; font-size: ${modalBodyFontSize}; font-weight: ${modalBodyFontWeight}; font-family: ${modalBodyFontFamily};`;
  const titleStyles = `color: ${modalBodyTitleTextColor}; font-size: ${modalBodyTitleFontSize}; font-weight: ${modalBodyTitleFontWeight}; font-family: ${modalBodyTitleFontFamily}; text-transform: ${modalBodyTitleTextTransform};`;

  const getCatchLine = () => {
    switch (type) {
      case 'budget-booster-page':
      case 'budget-booster-page-cg':
      case 'budget-booster-page-arun':
      case 'hw-calculator-page-retirement':
        return `<h2 style="padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;font-size: ${FONT_SIZE_TITLE}px;${titleStyles}">
          You could live in a property valued at <span style="font-size: ${FONT_SIZE_LARGE_EM};line-height: ${LINE_HEIGHT_LARGE_EM};">${formattedFinalPrice}</span>
        </h2>
        <h2 style="padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;font-size: ${FONT_SIZE_TITLE}px;${titleStyles}">
          Boosting your budget by ${formattedSaving}!
        </h2>`;
      case 'budget-booster-listing':
      case 'budget-booster-listing-cg':
      case 'budget-booster-listing-arun':
      case 'hw-calculator-listing-retirement':
        return `<h2 style="padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;font-size: ${FONT_SIZE_TITLE}px;${titleStyles}">
          You could pay just <span style="font-size: ${FONT_SIZE_LARGE_EM};line-height: ${LINE_HEIGHT_LARGE_EM};">${formattedFinalPrice}</span> saving <span>${formattedSaving}</span>
        </h2>`;
      default:
        return `<h2 style="padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;font-size: ${FONT_SIZE_TITLE}px;${titleStyles}">
          You could pay just <span style="font-size: ${FONT_SIZE_LARGE_EM};line-height: ${LINE_HEIGHT_LARGE_EM};">${formattedFinalPrice}</span> saving <span style="line-height: ${LINE_HEIGHT_LARGE_EM};">${formattedSaving}</span>
        </h2>`;
    }
  };

  const getBodyText = () => {
    switch (type) {
      case 'budget-booster-page':
      case 'budget-booster-page-cg':
      case 'budget-booster-page-arun':
        return `<p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          With a one-off payment of <span>${formattedPrice}</span>, you could secure a higher valued property for much less than the market value, and even free up more money to enjoy in your retirement, with a Homewise Home for Life Plan.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Homewise could boost your current budget, helping you to find your dream home sooner. You would live there rent-free and mortgage-free for your lifetime, and you can secure a guaranteed inheritance for loved ones should you wish.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;">
        Click below to visit the Homewise website and tailor your own personalised, no-obligation estimate – see just how much your perfect property could cost with a Home for Life Plan!
        </p>`;
      case 'hw-calculator-page-retirement':
        return `<p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          With a one-off payment of <span>${formattedPrice}</span>, you could secure a higher valued property for much less than the advertised sale price, and even free up more money to enjoy in your retirement, with a Homewise Home for Life Plan.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Relax and enjoy living in your new home, with no rent, mortgage or interest payments. There’s no financial lending with a Home for Life Plan, so you can be free from financial burdens.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Every Home for Life Plan is bespoke and tailored to your needs and includes the option to secure a guaranteed inheritance for your loved ones too, if you wish.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-top:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          <strong>The perfect home for your retirement could be within reach! Click the X at the top of this message and enquire about properties in your ideal location today.</strong>
        </p>`;
      case 'budget-booster-listing':
      case 'budget-booster-listing-cg':
      case 'budget-booster-listing-arun':
        return `<p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          If you’re aged 60 or over, you could secure a property valued at <span>${formattedPrice}</span> for much less, and even free up more money to enjoy in your retirement, ${
          type === 'budget-booster-listing-cg'
            ? 'with a Lifetime Lease purchase through the Homewise "Home for Life Plan"'
            : 'with a Home for Life Plan from Homewise'
        }.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Homewise could boost your current budget, helping you to find your dream home sooner. You would live there rent-free and mortgage-free for your lifetime, 
          and you can secure a guaranteed inheritance for loved ones should you wish.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;">
          Click below to visit the Homewise website and tailor your own personalised, no-obligation estimate – see just how much your perfect property could cost with a Home for Life Plan!
        </p>`;
      case 'hw-calculator-listing-retirement':
        return `<p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          You could secure a retirement property valued at <span>${formattedPrice}</span> for much less, and even free up more money to enjoy in your retirement, with a Home for Life Plan from Homewise.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Relax and enjoy living in your new home, with no rent, mortgage or interest payments. There’s no financial lending with a Home for Life Plan, so you can be free from financial burdens.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Every Home for Life Plan is bespoke and tailored to your needs and includes the option to secure a guaranteed inheritance for your loved ones too, if you wish.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-top:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          <strong>The perfect home for your retirement could be within reach! Click the X at the top of this message and enquire about properties in your ideal location today.</strong>
        </p>`;
      default:
        return `<p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          If you're aged 60 or over, you could secure a property valued at <span>${formattedPrice}</span> for much less, 
          and even free up more money to enjoy in your retirement, with a Home for Life Plan from Homewise.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;margin-bottom:${SPACING_HALF *
          SPACING_MULTIPLIER}px;">
          Homewise could boost your current budget, helping you to find your dream home sooner. 
          You would live there rent-free and mortgage-free for your lifetime, and you can secure a guaranteed inheritance for loved ones should you wish.
        </p>

        <p style="${bodyTextStyle} line-height: ${REDUCED_LINE_HEIGHT};padding:0;margin:0;">
          Click below to visit the Homewise website and tailor your own personalised, no-obligation estimate – see just how much your perfect property could cost with a Home for Life Plan!
        </p>`;
    }
  };

  return `
    ${getCatchLine()}

    ${getBodyText()}
  `;
};

export default createModalBodyContent;
