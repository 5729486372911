import {
  DEFAULT_MODAL_Z_INDEX,
  DEFAULT_LINE_HEIGHT,
  SECONDARY,
  WIDGET_TITLE_FONT_SIZE,
  WHITE,
  GREY_DARK,
  PRIMARY,
  PRIMARY_SHADE,
  ERROR,
  FONT_SIZE_TITLE,
  DEFAULT_BUTTON_FONT_SIZE,
  PEACH_SHADE,
  PEACH,
  BUDGET_WIDGET_TITLE_FONT_SIZE,
  WIDGET_TITLE_FONT_SIZE_BOOSTER_LISTING_CG,
  BUDGET_MODAL_HEADER_TITLE_FONT_SIZE,
  HORIZONTAL_LOGO_WIDTH,
} from './constants';
import {
  CalculatorStyleOptions,
  CalculatorOptions,
  CalculatorType,
  CalculatorTypeConfig,
} from './types';

const DEFAULT_STYLE_OPTIONS: CalculatorStyleOptions = {
  modalZIndex: DEFAULT_MODAL_Z_INDEX,
  lineHeight: DEFAULT_LINE_HEIGHT,
  widgetBackgroundColor: SECONDARY,
  widgetTitleFontFamily: 'inherit',
  widgetTitleFontSize: `${WIDGET_TITLE_FONT_SIZE}px`,
  widgetTitleFontWeight: 'inherit',
  widgetTitleTextColor: WHITE,
  widgetTitleTextTransform: 'inherit',
  widgetLogoColored: false,
  formBackgroundColor: WHITE,
  formTitleFontFamily: 'inherit',
  formTitleFontSize: `${WIDGET_TITLE_FONT_SIZE}px`,
  formTitleFontWeight: 'inherit',
  formTitleTextColor: 'inherit',
  formTitleTextTransform: 'inherit',
  formSubtitleFontSize: `16px`,
  formSubtitleFontFamily: 'inherit',
  formSubtitleFontWeight: 'inherit',
  formSubtitleTextColor: 'inherit',
  formSubtitleTextTransform: 'inherit',
  formLabelFontFamily: 'inherit',
  formLabelFontSize: '16px',
  formLabelFontWeight: 'inherit',
  formLabelTextColor: GREY_DARK,
  formButtonColor: PRIMARY,
  formButtonHoverColor: PRIMARY_SHADE,
  formButtonFontFamily: 'inherit',
  formButtonFontWeight: 'inherit',
  formButtonFontSize: `${DEFAULT_BUTTON_FONT_SIZE}px`,
  formButtonTextColor: WHITE,
  formButtonHoverTextColor: WHITE,
  formButtonTextTransform: 'uppercase',
  formErrorTextColor: ERROR,
  modalHeaderBackgroundColor: PRIMARY,
  modalHeaderTitleFontFamily: 'inherit',
  modalHeaderTitleFontSize: `${FONT_SIZE_TITLE}px`,
  modalHeaderTitleFontWeight: 'inherit',
  modalHeaderTitleTextColor: WHITE,
  modalHeaderTitleTextTransform: 'inherit',
  modalBodyBackgroundColor: SECONDARY,
  modalBodyFontFamily: 'inherit',
  modalBodyFontSize: 'inherit',
  modalBodyFontWeight: 'inherit',
  modalBodyTextColor: WHITE,
  modalBodyTitleFontFamily: 'inherit',
  modalBodyTitleFontSize: `${FONT_SIZE_TITLE}px`,
  modalBodyTitleFontWeight: 'inherit',
  modalBodyTitleTextColor: PRIMARY,
  modalBodyTitleTextTransform: 'inherit',
  modalButtonColor: PEACH,
  modalButtonHoverColor: PEACH_SHADE,
  modalButtonFontFamily: 'inherit',
  modalButtonFontWeight: 'inherit',
  modalButtonFontSize: `${DEFAULT_BUTTON_FONT_SIZE}px`,
  modalButtonTextColor: WHITE,
  modalButtonHoverTextColor: WHITE,
  modalButtonTextTransform: 'uppercase',
  modalLogoColored: false,
  widgetBorderColor: 'inherit',
  boosterIconLogoWidth: HORIZONTAL_LOGO_WIDTH,
  boosterIconLogoColor: PRIMARY,
  lineBreakerColor: PRIMARY,
};

const TYPE_DEFAULT_STYLE_OPTIONS: Record<
  CalculatorType,
  Partial<CalculatorStyleOptions>
> = {
  listing: {},
  page: {},
  'budget-booster-listing': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'budget-booster-listing-cg': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    formTitleFontSize: `${WIDGET_TITLE_FONT_SIZE_BOOSTER_LISTING_CG}px`,
    formSubtitleFontSize: '14px',
    formLabelFontSize: '14px',
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'budget-booster-listing-arun': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'hw-calculator-listing-retirement': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'budget-booster-page': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'budget-booster-page-cg': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'budget-booster-page-arun': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
  'hw-calculator-page-retirement': {
    widgetTitleFontSize: `${BUDGET_WIDGET_TITLE_FONT_SIZE}px`,
    modalHeaderTitleFontSize: `${BUDGET_MODAL_HEADER_TITLE_FONT_SIZE}px`,
  },
};

export const getStyleOptionsWithDefaults = (
  options: Partial<CalculatorOptions>
): CalculatorStyleOptions => {
  const typeDefaultStyles =
    options.type && options.type in TYPE_DEFAULT_STYLE_OPTIONS
      ? TYPE_DEFAULT_STYLE_OPTIONS[options.type]
      : {};

  return {
    ...DEFAULT_STYLE_OPTIONS,
    ...typeDefaultStyles,
    ...options,
  };
};

const CALCULATOR_CONFIG_BY_TYPE: Record<
  CalculatorType,
  CalculatorTypeConfig
> = {
  listing: { includeGender: true, layout: 'vertical' },
  page: { includeGender: true, layout: 'vertical' },
  'budget-booster-listing': { includeGender: false, layout: 'vertical' },
  'budget-booster-listing-cg': { includeGender: false, layout: 'vertical' },
  'budget-booster-listing-arun': { includeGender: false, layout: 'horizontal' },
  'hw-calculator-listing-retirement': {
    includeGender: false,
    layout: 'horizontal',
  },
  'budget-booster-page': { includeGender: false, layout: 'vertical' },
  'budget-booster-page-cg': { includeGender: false, layout: 'vertical' },
  'budget-booster-page-arun': { includeGender: false, layout: 'horizontal' },
  'hw-calculator-page-retirement': {
    includeGender: false,
    layout: 'horizontal',
  },
};

export function getCalculatorConfigFor(
  type: CalculatorType
): CalculatorTypeConfig {
  return CALCULATOR_CONFIG_BY_TYPE[type];
}
