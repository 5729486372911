export interface ChangeEvent<T extends HTMLElement> extends Event {
  currentTarget: T;
}

interface SensibleMouseEvent<T extends HTMLElement> extends MouseEvent {
  currentTarget: T;
}

export type CSSStyle = Partial<
  Pick<
    CSSStyleDeclaration,
    Exclude<
      keyof CSSStyleDeclaration,
      | 'getPropertyPriority'
      | 'getPropertyValue'
      | 'item'
      | 'removeProperty'
      | 'setProperty'
      | 'length'
      | 'parentRule'
    >
  >
>;

interface IntrinsicProps {
  className?: string;
  style?: CSSStyle;
  onClick?: (event: SensibleMouseEvent<HTMLElement>) => void;
  onMouseOver?: (event: SensibleMouseEvent<HTMLElement>) => void;
  onMouseOut?: (event: SensibleMouseEvent<HTMLElement>) => void;
  innerHTML?: string;
}

interface HRElementProps extends IntrinsicProps {
  color: string;
}

interface PropsWithSubmit extends IntrinsicProps {
  onSubmit: (event: Event) => void;
}

interface PropsWithValue extends IntrinsicProps {
  value: string;
}

interface PropsWithValueAndSelected extends PropsWithValue {
  selected?: string;
}

interface PropsWithOnChange<T extends HTMLElement> extends IntrinsicProps {
  onChange: (event: ChangeEvent<T>) => void;
}

type PropsWithValueAndOnChange<T extends HTMLElement> = PropsWithValue &
  PropsWithOnChange<T>;

type AnyChildrenArray = readonly (string | number | HTMLElement)[];

type AnyChildren = number | string | HTMLElement | AnyChildrenArray;

type OptionChildren = readonly HTMLOptionElement[];

interface ElementMap {
  div: {
    element: HTMLDivElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  button: {
    element: HTMLButtonElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  form: {
    element: HTMLFormElement;
    props: PropsWithSubmit;
    children: AnyChildren;
  };
  p: {
    element: HTMLParagraphElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  h1: {
    element: HTMLHeadingElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  h2: {
    element: HTMLHeadingElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  h3: {
    element: HTMLHeadingElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  label: {
    element: HTMLLabelElement;
    props: IntrinsicProps;
    children: AnyChildren;
  };
  input: {
    element: HTMLInputElement;
    props: PropsWithValueAndOnChange<HTMLInputElement>;
    children: never;
  };
  select: {
    element: HTMLSelectElement;
    props: PropsWithOnChange<HTMLSelectElement>;
    children: OptionChildren;
  };
  option: {
    element: HTMLOptionElement;
    props: PropsWithValueAndSelected;
    children: string;
  };
  hr: {
    element: HTMLHRElement;
    props: HRElementProps;
    children: AnyChildren;
  };
}

const MATCHES_HANDLER = /^on([A-Z][a-zA-Z]+)$/;

const applyStyles = (element: HTMLElement, style: CSSStyle) => {
  (Object.keys(style) as (keyof CSSStyle)[]).forEach(property => {
    const value = style[property];

    /* istanbul ignore else */
    if (typeof value !== 'undefined') {
      element.style[property] = value;
    }
  });
};

const createElement = <T extends keyof ElementMap>(
  type: T,
  props: ElementMap[T]['props'],
  children?: ElementMap[T]['children']
): ElementMap[T]['element'] => {
  const element: ElementMap[T]['element'] = document.createElement(type);

  (Object.keys(props) as (keyof ElementMap[T]['props'])[]).forEach(key => {
    // Type safety is guaranteed by a combination of the ElementMap, and the below if statements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = props[key] as any;

    /* istanbul ignore else */
    if (typeof value !== 'undefined') {
      const handlerMatch = MATCHES_HANDLER.exec(key.toString());

      if (handlerMatch) {
        const eventType = handlerMatch[1].toLowerCase();

        element.addEventListener(eventType, value);

        if (eventType === 'change') {
          // Ensures same functionality across all browsers
          element.addEventListener('input', value);
        }
      } else if (key === 'style') {
        applyStyles(element, value);
      } else {
        element[key] = value;
      }
    }
  });

  if (typeof children !== 'undefined') {
    const childArray = ([] as AnyChildrenArray).concat(children);

    childArray.forEach(child => {
      if (typeof child === 'string' || typeof child === 'number') {
        const textNode = document.createTextNode(child.toString());
        element.appendChild(textNode);
      } else {
        element.appendChild(child);
      }
    });
  }

  return element;
};

export default createElement;
