import ce from './create-element';
import { SPACING_QUARTER } from './constants';
import { CalculatorStyleOptions } from './types';

interface ErrorProps {
  text: string;
  styleOptions: CalculatorStyleOptions;
}

const createError = (props: ErrorProps): HTMLParagraphElement =>
  ce(
    'p',
    {
      style: {
        padding: '0',
        margin: `${SPACING_QUARTER}px 0`,
        color: props.styleOptions.formErrorTextColor,
      },
    },
    [props.text]
  );

export default createError;
