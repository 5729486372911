import {
  SerializerResultErrors,
  SerializerResultValues,
  Values,
  SerializedValues1Person,
  SerializedValues2Person,
  Gender,
  Age,
} from './types';
import {
  MATCHES_INVALID_NUMBER_CHARS,
  VALID_GENDER_OPTIONS,
  VALID_PERSONS_OPTIONS,
  VALID_AGE_OPTIONS,
  INITIAL_VALID_GENDER_OPTIONS,
  INITIAL_VALID_AGE_OPTIONS,
  INITIAL_VALID_PERSONS_OPTIONS,
} from './constants';
import { formatCurrency } from './utils';

export const serializeString = (
  key: string,
  value: unknown,
  defaultValue: string
): string => {
  if (typeof value === 'undefined' || typeof value === 'string') {
    return value || defaultValue;
  }

  throw new Error(`Invalid value for "${key}": ${value}\nMust be a string`);
};

export const serializeOptions = (
  key: string,
  value: unknown,
  validOptions: readonly string[],
  defaultValue: string
): string => {
  const stringValue = serializeString(key, value, defaultValue);

  if (validOptions.indexOf(stringValue) >= 0) {
    return stringValue;
  }

  throw new Error(
    `Invalid option for "${key}": ${stringValue}\nMust one of ${validOptions
      .map(option => `"${option}"`)
      .join(', ')}`
  );
};

export const serializePrice = (value: string | undefined): string => {
  const stringValue = serializeString('price', value, '');
  const numberValue = parseFloat(
    stringValue.replace(MATCHES_INVALID_NUMBER_CHARS, '')
  );

  if (Number.isNaN(numberValue)) {
    return '£';
  }

  return `${formatCurrency(numberValue)}`;
};

export const serializeInitialValues = (
  initialValues: Partial<Values> | undefined,
  includeGender: boolean
): Values => ({
  price: serializePrice(initialValues?.price),
  persons: serializeOptions(
    'persons',
    initialValues?.persons,
    INITIAL_VALID_PERSONS_OPTIONS,
    ''
  ),
  ...(includeGender
    ? {
        gender1: serializeOptions(
          'gender1',
          initialValues?.gender1,
          INITIAL_VALID_GENDER_OPTIONS,
          ''
        ),
      }
    : {}),
  age1: serializeOptions(
    'age1',
    initialValues?.age1,
    INITIAL_VALID_AGE_OPTIONS,
    ''
  ),
  ...(includeGender
    ? {
        gender2: serializeOptions(
          'gender2',
          initialValues?.gender2,
          INITIAL_VALID_GENDER_OPTIONS,
          ''
        ),
      }
    : {}),
  age2: serializeOptions(
    'age2',
    initialValues?.age2,
    INITIAL_VALID_AGE_OPTIONS,
    ''
  ),
});

export const serializeValues = (
  values: Values,
  includeGender: boolean
): SerializerResultErrors | SerializerResultValues => {
  const errors = [];
  const serializedValues = {} as
    | SerializedValues1Person
    | SerializedValues2Person;

  if (typeof values.price === 'undefined' || !values.price) {
    errors.push('No price entered');
  } else {
    const priceNumber = parseFloat(
      values.price.replace(MATCHES_INVALID_NUMBER_CHARS, '')
    );

    if (Number.isNaN(priceNumber)) {
      errors.push('Invalid value');
    } else {
      serializedValues.price = priceNumber;
    }
  }

  if (includeGender) {
    if (!values.gender1) {
      errors.push('No gender for person 1');
    } else if (VALID_GENDER_OPTIONS.indexOf(values.gender1) < 0) {
      errors.push('Invalid gender for person 1');
    } else {
      serializedValues.gender1 = values.gender1 as Gender;
    }
  }

  if (!values.age1) {
    errors.push('No age for person 1');
  } else if (VALID_AGE_OPTIONS.indexOf(values.age1) < 0) {
    errors.push('Invalid age for person 1');
  } else {
    serializedValues.age1 = parseInt(values.age1, 10) as Age;
  }

  const personsNumber = parseInt(values.persons, 10);

  if (
    VALID_PERSONS_OPTIONS.indexOf(values.persons) < 0 ||
    Number.isNaN(personsNumber)
  ) {
    errors.push('Invalid number of people');
  } else {
    serializedValues.persons = personsNumber as 1 | 2;
  }

  if (serializedValues.persons === 2) {
    if (includeGender) {
      if (!values.gender2) {
        errors.push('No gender for person 2');
      } else if (VALID_GENDER_OPTIONS.indexOf(values.gender2) < 0) {
        errors.push('Invalid gender for person 2');
      } else {
        serializedValues.gender2 = values.gender2 as Gender;
      }
    }

    if (!values.age2) {
      errors.push('No age for person 2');
    } else if (VALID_AGE_OPTIONS.indexOf(values.age2) < 0) {
      errors.push('Invalid age for person 2');
    } else {
      serializedValues.age2 = parseInt(values.age2, 10) as Age;
    }
  }

  if (errors.length) {
    return {
      errors,
    };
  }

  return {
    values: serializedValues,
  };
};
